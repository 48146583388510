import React, { useState, useEffect } from "react";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "./../../components/Layout/Layout";
import "../../styles/Dashboard.scss";
import "../../styles/Products.scss";
import axios from "axios";
import { useURL } from "../../context/url";

import Product from "../Product";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
const Products = () => {
  const [products, setProducts] = useState([]);
  const [url, setURL] = useURL();

  //getall products
  const getAllProducts = async () => {
    try {
      const { data } = await axios.get(url + "/api/v1/product/get-product");
      setProducts(data.products);
      // console.log(data.products[0].photos[0]);
    } catch (error) {
      console.log(error);
      toast.error("Someething Went Wrong");
    }
  };

  //lifecycle method
  useEffect(() => {
    getAllProducts();
  }, []);
  return (
    <Layout>
      <div className="order_page container-fluid p-0 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9 p-5 dashboard_right">
            <div className="dashboard_right_sub">
              <h1 className="sec-heading">All Products List</h1>
              <div className="product_cat d-flex flex-wrap">
                {products?.map((product) => (
                  <Link
                    key={product._id}
                    to={`/dashboard/admin/product/${product.slug}`}
                    className="product-link product-card"
                  >
                    <Product key={product._id} product={product} />
                    {/* //   <div className="card">
                //     <div className="thumbnail">
                //       <img
                //         // src={`/api/v1/product/product-photo/${p._id}`}
                //         src={url + `/${p.photos[1]}`}
                //         className="card-img-top"
                //         alt={p.name}
                //       />
                //     </div>
                //     <div className="prod-details">
                //       <div className="name">
                //         <h5>{p?.name}</h5>
                //       </div>
                //       <p className="price">{p.description}</p>
                //     </div>
                //   </div> */}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
