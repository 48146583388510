import React from "react";
import Layout from "../components/Layout/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout title={"Privacy Policy"}>
      <div className="row contactus">
        <div className="col-md-12 contactus_para">
          <h1>Privacy Policy</h1>

          <h3>Introduction</h3>

          <p>
            Sumoyaa Sales Corporation is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [website address], use our services, or otherwise interact with us.
          </p>

          <h3>1. Information We Collect</h3>

          <p>We may collect and process the following types of information about you:</p>
          <ul>
            <li><strong>Personal Information:</strong> Name, address, email address, phone number, and other similar information.</li>
            <li><strong>Transaction Information:</strong> Details of products and services you have purchased from us.</li>
            <li><strong>Technical Information:</strong> IP address, browser type, operating system, and other technical data.</li>
            <li><strong>Usage Information:</strong> Information about how you use our website, products, and services.</li>
          </ul>

          <h3>2. How We Collect Information</h3>

          <p>We collect information in the following ways:</p>
          <ul>
            <li><strong>Directly from You:</strong> When you fill out forms, make a purchase, or communicate with us.</li>
          </ul>

          <h3>3. Use of Your Information</h3>

          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>To process transactions and manage your orders.</li>
            <li>To communicate with you, including sending promotional materials and updates.</li>
            <li>To improve our website, products, and services.</li>
            <li>To comply with legal obligations and enforce our terms of service.</li>
          </ul>

          <h3>4. Sharing Your Information</h3>

          <p>We may share your information with:</p>
          <ul>
            <li><strong>Service Providers:</strong> Third-party vendors who assist us in operating our business and providing services to you.</li>
            <li><strong>Business Partners:</strong> Partners with whom we collaborate to offer you products and services.</li>
            <li><strong>Legal Authorities:</strong> When required by law, regulation, or legal process.</li>
          </ul>

          <h3>5. Security of Your Information</h3>

          <p>
            We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.
          </p>

          <h3>6. Your Rights</h3>

          <p>You have the following rights regarding your information:</p>
          <ul>
            <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
            <li><strong>Correction:</strong> You can request corrections to any inaccurate or incomplete personal information.</li>
            <li><strong>Deletion:</strong> You can request the deletion of your personal information, subject to certain legal restrictions.</li>
            <li><strong>Objection:</strong> You can object to the processing of your personal information in certain circumstances.</li>
          </ul>

          <h3>7. Changes to This Privacy Policy</h3>

          <p>
            We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
