import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useURL } from "../context/url";
import { useCart } from "../context/cart";
import "../styles/CategoryProductStyles.scss";
import Product from "./Product";
import axios from "axios";
import { AiOutlineReload } from "react-icons/ai";


const CategoryProduct = () => {
  const params = useParams();
  const [url, setURL] = useURL();
  const [cart, setCart] = useCart();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params?.slug) getPrductsByCat();
  }, [params?.slug]);
  const getPrductsByCat = async () => {
    try {
      const { data } = await axios.get(
        url + `/api/v1/product/product-category/${params.slug}`
      );
      setProducts(data?.products);
      setCategory(data?.category);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="main-content category-page">
        <div className="layout">
          <div className="sec-heading">{category?.name}</div>
          <div className="product_cat flex-wrap">
            {products?.map((product) => (
              <div
              className="product-card"
              key={product._id}
              onClick={() => navigate(`/product/${product.slug}`)}
              >
              <Product key={product._id} product={product} />
              {/* //   <div className="thumbnail">
              //     <img
              //       src={url + `/${p.photos[1]}`}
              //       className="card-img-top"
              //       alt={p.name}
              //     />
              //   </div>

              //   <div className="prod-details">
              //     <div className="name">
              //       <h5>{p?.name}</h5>
              //     </div>
              //     <div className="prod-details_lower">
              //       <div className="price">&#8377;{p?.price}</div>
              //       <button
              //         className="btn btn-dark btn-sm ms-1"
              //         onClick={() => {
              //           setCart([...cart, p]);
              //           localStorage.setItem(
              //             "cart",
              //             JSON.stringify([...cart, p])
              //           );
              //           toast.success("Item Added to cart");
              //         }}
              //       >
              //         BUY
              //       </button>
              //     </div>
              //   </div> */}
              </div>
            ))}
          </div>
          {/* <div className="m-2 p-3">
            {products && (
              <button
                className="btn loadmore"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {loading ? (
                  "Loading ..."
                ) : (
                  <>
                    {" "}
                    Loadmore <AiOutlineReload />
                  </>
                )}
              </button>
            )}
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default CategoryProduct;
