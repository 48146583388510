import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useCart } from "../context/cart";
import { useAuth } from "../context/auth";
import { useURL } from "../context/url";
import { useNavigate } from "react-router-dom";
import DropIn from "braintree-web-drop-in-react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";

import logo from "../Logo.png";
import toast from "react-hot-toast";
import "../styles/CartPage.scss";

const CartPage = () => {
  const [url, setURL] = useURL();
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const [clientToken, setClientToken] = useState("");
  const [instance, setInstance] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const totalPrice = () => {
    try {
      let total = 0;
      cart?.forEach((item) => {
        total += item.price;
      });
      return total;
    } catch (error) {
      console.log(error);
    }
  };

  const truncateDescription = (description) => {
    if (description.length > 125) {
      return description.substring(0, 125) + "...";
    }
    return description;
  };

  const removeCartItem = (pid) => {
    try {
      let myCart = [...cart];
      let index = myCart.findIndex((item) => item._id === pid);
      myCart.splice(index, 1);
      setCart(myCart);
      localStorage.setItem("cart", JSON.stringify(myCart));
      toast.success("Item Removed from Cart");
    } catch (error) {
      console.log(error);
    }
  };


  const checkouthandler = async () => {
    try {
      const amount = totalPrice();
      const products = cart.map((item) => ({
        _id: item._id,
        quantity: item.quantity || 1, // Ensure you have quantity in your cart items
      }));

      const { data: keyData } = await axios.get(
        url + "/api/v1/payment/getkey",
      );

      const { data: orderData } = await axios.post(
        url + "/api/v1/payment/checkout",
        { amount, products },
      );

      console.log(orderData);
      const { key } = keyData;
      const { order } = orderData;
      console.log(order);

      const options = {
        key,
        amount: amount * 100,
        currency: "INR",
        name: "Sumoyaa",
        description: "Product Purchase",
        image: logo,
        order_id: order.id,
        callback_url: url + "/api/v1/payment/paymentverification",
        prefill: {
          name: auth?.user?.name,
          email: auth?.user?.email,
          contact: auth?.user?.phone,
        },
        notes: {
          address: auth?.user?.address,
        },
        theme: {
          color: "#3399cc",
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Layout>
      <section className="cart_page h-100 gradient-custom">
        <div className="container py-5">
          <div className="row d-flex justify-content-center my-4">
            <div className="col-md-8">
              <div className="card mb-4">
                <div className="d-flex justify-content-between card-header py-3">
                  <h5 className="mb-0">Cart - {cart.length} items</h5>
                  <h5 className="mb-0">
                    {!auth?.user
                      ? "Guest"
                      : `${auth?.token && auth?.user?.name}`}
                  </h5>
                </div>
                <div className="card-body">
                  {cart.map((item) => (
                    <div className="row mb-4" key={item._id}>
                      <div className="col-lg-3 col-md-12 mb-4 mb-lg-0">
                        <Link to={`/product/${item._id}`}>
                          <div
                            className="bg-image hover-overlay hover-zoom ripple rounded"
                            data-mdb-ripple-color="light"
                          >
                            <img
                              src={url + `/${item.photos[1]}`}
                              className="w-100"
                              alt={item.name}
                            />
                          </div>
                        </Link>
                      </div>

                      <div className="cart_item_subdetails col-lg-5 col-md-6 mb-4 mb-lg-0">
                        <Link to={`/product/${item._id}`}>
                          <h5>{item.name}</h5>
                        </Link>
                        <p>
                          <ReactMarkdown>
                            {truncateDescription(item.description)}
                          </ReactMarkdown>
                        </p>
                      </div>

                      <div className="price_remove_cart col-lg-4 col-md-6 mb-4 mb-lg-0">
                        <p className="cart_product_price">
                          <strong>Price: &#8377; {item.price}</strong>
                        </p>
                        <div className="btn_cart">
                          <button
                            type="button"
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => removeCartItem(item._id)}
                          >
                            <i className="fas fa-trash"></i> Remove item
                          </button>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-header py-3">
                  <h5 className="mb-0">Summary</h5>
                </div>
                <div className="card-body">
                  {auth?.user?.address ? (
                    <>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between user_details_cart">
                          <div className="address_cart">
                            <h6>Current Address</h6>
                            <p>{auth?.user?.address}</p>
                          </div>
                          <div className="contact_cart">
                            <h6>Contact no.</h6>
                            <p>{auth?.user?.phone}</p>
                          </div>
                        </div>
                        <div className="btn_cart">
                          <button
                            className="btn btn-outline-warning"
                            onClick={() => navigate("/dashboard/user")}
                          >
                            Update Details
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="mb-3">
                      {auth?.token ? (
                        <button
                          className="btn btn-outline-warning"
                          onClick={() => navigate("/dashboard/user/profile")}
                        >
                          Update Address
                        </button>
                      ) : (
                        "Guest"
                      )}
                    </div>
                  )}
                  <hr />
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Products
                      <span>&#8377; {totalPrice()}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      Shipping
                      <span>Gratis</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div>
                        <strong>Total amount</strong>
                        <p className="mb-0">(including VAT)</p>
                      </div>
                      <span>
                        <strong>&#8377; {totalPrice()}</strong>
                      </span>
                    </li>
                  </ul>
                  {auth?.token ? (
                    <div className="btn_cart">
                      <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={checkouthandler}
                      >
                        Go to checkout
                      </button>
                    </div>
                  ) : (
                    <div className="btn_cart">
                      <button
                        className="btn btn-outline-warning"
                        onClick={() =>
                          navigate("/login", {
                            state: "/cart",
                          })
                        }
                      >
                        Please Login to Checkout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CartPage;
