import React from "react";
import { Link } from "react-router-dom";
import useCategory from "../../hooks/useCategory";
import "../../styles/Footer.scss";
import { FaLocationArrow, FaMobileAlt, FaEnvelope } from "react-icons/fa";

// import Payment from "../../assets/payments.png";

const Footer = () => {
  const categories = useCategory();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="col">
          <div className="title">About</div>
          <Link to="/about">
            <span className="text">Company</span>
          </Link>
          <Link to="/ratings">
            <span className="text">Authorisation & Ratings</span>
          </Link>
          <Link to="/management">
            <span className="text">Management</span>
          </Link>
        </div>
        <div className="col">
          <div className="title">Contact Us</div>
          <div className="c-item">
            <FaLocationArrow />
            <div className="text">
              C-157 New Moti Nagar ( Head office )
              <br />
              Delhi - 110015
            </div>
          </div>
          <div className="c-item">
            <FaMobileAlt />
            <div className="text">Phone: 9560656900</div>
          </div>
          <div className="c-item">
            <FaEnvelope />
            <div className="text">Email: sumoyaaaquafresh@sumoyaa.in</div>
          </div>
        </div>
        <div className="col">
          <div className="title">Categories</div>
          {categories?.map((c) => (
            <span className="text">
              <Link to={`/category/${c.slug}`}>{c.name}</Link>
            </span>
          ))}
        </div>
        <div className="col">
          <div className="title">T&C's</div>
          {/* <span className="text">Terms & Conditions</span> */}
          <Link to="/privacy-policy">
            <span className="text">Privacy Policy</span>
          </Link>
          <Link to="/shipping-policy">
            <span className="text">Shipping Policy</span>
          </Link>
          <Link to="/refund-policy">
            <span className="text">Return & Refund Policy</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
