import React, { useState, useEffect,useContext  } from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import SearchInput from "../Form/SearchInput";
import useCategory from "../../hooks/useCategory";
import { useCart } from "../../context/cart";
import { useHeight } from "../../context/HeaderHeightContext";
import { Badge } from "antd";
import { IoPersonOutline } from "react-icons/io5";

import { CgDrive, CgShoppingCart } from "react-icons/cg";
import logo from "../../Logo.png";
import "../../styles/Header.scss";
const Header = () => {
  const [auth, setAuth] = useAuth();
  const [cart] = useCart();
  const { headerHeight } = useHeight();
  const categories = useCategory();

   const [isMobile, setIsMobile] = useState(false);

   useEffect(() => {
     const handleResize = () => {
       setIsMobile(window.innerWidth <= 768);
     };

     // Initial check
     handleResize();

     // Add event listener
     window.addEventListener("resize", handleResize);

     // Clean up event listener on unmount
     return () => {
       window.removeEventListener("resize", handleResize);
     };
   }, []);
  
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
  };
  return (
    <>
      <nav
        className="navbar navbar-dark navbar-expand-lg bg-dark fixed-top"
        data-bs-theme="dark"
        style={{ marginBottom: headerHeight }}
      >
        <div className="mobile-container">
          <div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <Link to="/" className="navbar-brand">
              <img src={logo} alt="Aquafresh" />
              Sumoyaa
            </Link>
          </div>

          {isMobile && (
            <div className="mobile-avatars">
              {!auth?.user ? (
                  <div className="nav-item">
                    <NavLink to="/login" className="nav-link">
                      Login
                    </NavLink>
                  </div>
              ) : (
                <div className="nav-item dropdown">
                  <NavLink
                    className="nav-link user-header"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    style={{ border: "none" }}
                  >
                    <IoPersonOutline />
                  </NavLink>
                  <ul className="dropdown-menu dropdown-menu-left">
                    <li>
                      <NavLink
                        to={`/dashboard/${
                          auth?.user?.role === 1 ? "admin" : "user"
                        }`}
                        className="dropdown-item"
                      >
                        Dashboard
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={handleLogout}
                        to="/login"
                        className="dropdown-item"
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
              <div className="nav-item">
                <NavLink to="/cart" className="nav-link">
                  <CgShoppingCart />
                </NavLink>
              </div>
            </div>
          )}
        </div>
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-1">
              <SearchInput />
              <li className="nav-item home_btn">
                <NavLink to="/" className="nav-link">
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to={"/categories"}
                  data-bs-toggle="dropdown"
                >
                  Categories
                </Link>
                <ul className="dropdown-menu">
                  {/* <li>
                    <Link className="dropdown-item" to={"/categories"}>
                      All Categories
                    </Link>
                  </li> */}
                  {categories?.map((c) => (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={`/category/${c.slug}`}
                      >
                        {c.name}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link
                      className="dropdown-item"
                      to={`/entertainment`}
                    >
                      Entertainment
                    </Link>
                  </li>
                </ul>
              </li>

              {!auth?.user ? (
                <>
                  <li className="nav-item">
                    <NavLink to="/register" className="nav-link">
                      Register
                    </NavLink>
                  </li>
                  {!isMobile && (
                    <li className="nav-item">
                      <NavLink to="/login" className="nav-link">
                        Login
                      </NavLink>
                    </li>
                  )}
                </>
              ) : (
                <>
                  {!isMobile && (
                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link user-header"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        style={{ border: "none" }}
                      >
                        <IoPersonOutline />
                      </NavLink>
                      <ul className="dropdown-menu">
                        <li>
                          <NavLink
                            to={`/dashboard/${
                              auth?.user?.role === 1 ? "admin" : "user"
                            }`}
                            className="dropdown-item"
                          >
                            Dashboard
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={handleLogout}
                            to="/login"
                            className="dropdown-item"
                          >
                            Logout
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                  )}
                </>
              )}
              {!isMobile && (
                <li className="nav-item">
                  <NavLink to="/cart" className="nav-link">
                    <Badge
                      count={cart?.length}
                      showZero
                      offset={[10, -5]}
                      className="ant-badge"
                    >
                      <CgShoppingCart />
                    </Badge>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
