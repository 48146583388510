// pages/HomePage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../components/Layout/Layout";
import "../styles/Homepage.scss";
import { useURL } from "../context/url";
import Banner from "./Banner";
import Product from "./Product";

const HomePage = () => {
  const navigate = useNavigate();
  const [url, setURL] = useURL();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          url + "/api/v1/product/product-list/1"
        ); // Assuming the endpoint fetches all products
        setLoading(false);
        setProducts(data.products);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout title={"Aquafresh"}>
      {/* banner image */}
      <Banner />
      {/* banner image */}
      <div className="main-content">
        <div className="layout">
          <div className="sec-heading">Popular Products</div>
          <div className="product_cat flex-wrap">
            {products?.map((product) => (
              <div
              className="product-card"
              key={product._id}
              onClick={() => navigate(`/product/${product.slug}`)}
              >
              <Product key={product._id} product={product} />
              {/* //   <div className="thumbnail">
              //     <img
              //       src={url + `/${p.photos[1]}`}
              //       className="card-img-top"
              //       alt={p.name}
              //     />
              //   </div>

              //   <div className="prod-details">
              //     <div className="name">
              //       <h5>{p?.name}</h5>
              //     </div>
              //     <div className="prod-details_lower">
              //       <div className="price">&#8377;{p?.price}</div>
              //       <button
              //         className="btn btn-dark btn-sm ms-1"
              //         onClick={() => {
              //           setCart([...cart, p]);
              //           localStorage.setItem(
              //             "cart",
              //             JSON.stringify([...cart, p])
              //           );
              //           toast.success("Item Added to cart");
              //         }}
              //       >
              //         BUY
              //       </button>
              //     </div>
              //   </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
