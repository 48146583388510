import React, { useState, useEffect } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { useURL } from "../../context/url";
import moment from "moment";
import "../../styles/Dashboard.scss";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import toast from "react-hot-toast";


const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [auth] = useAuth();
  const [url] = useURL();

  const getOrders = async () => {
    try {
      const { data } = await axios.get(url + "/api/v1/payment/customer-order");
      const ordersWithPaymentStatus = await Promise.all(
        data.orders.map(async (order) => {
          const razorpayData = await getPaymentStatus(
            order.payment.razorpay_order_id
          );
          return { ...order, razorpayData };
        })
      );
      setOrders(ordersWithPaymentStatus);
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentStatus = async (razorpay_order_id) => {
    try {
      const { data } = await axios.get(
        `${url}/api/v1/payment/single-razorpay-order/?id=${razorpay_order_id}`
      );
      return data.response;
    } catch (error) {
      console.log(error);
      return "Error";
    }
  };

  const cancelOrder = async (orderId) => {
    if (window.confirm("Are you sure you want to cancel this order?")) {
      try {
        const { data } = await axios.post(
          url + "/api/v1/payment/cancel-order",
          {
            orderId,
          }
        );
        if (data.success) {
          // Re-fetch the orders list after cancellation
          getOrders();
          toast.success("Order Cancelled Successfully");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const truncateDescription = (description) => {
    if (description.length > 125) {
      return description.substring(0, 125) + "...";
    }
    return description;
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  return (
    <Layout title={"Your Orders"}>
      <div className="order_page container-fluid p-0 dashboard">
        <div className="row">
          <div className="col-md-3">
            <UserMenu />
          </div>
          <div className="col-md-9 p-5 dashboard_right">
            <div className="dashboard_right_sub">
              <div className="sec-heading">Your Orders - {orders?.length}</div>
              {orders.map((order) => (
                <div className="card mb-4" key={order._id}>
                  <div className="d-flex justify-content-between card-header py-3">
                    <h5 className="mb-0">
                      Total Products - {order?.products?.length}
                    </h5>
                    <div className="d-flex align-items-center justify-content-center gap-4">
                      <h5 className="mb-0">
                        Amount - &#8377; {order.payment.amount}
                      </h5>
                      {order.status !== "Cancelled" && (
                        <button
                          type="button"
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            cancelOrder(order._id);
                          }}
                        >
                          Cancel Order
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    {order.products.map((product, index) => (
                      <div className="row mb-4" key={product.product._id}>
                        <div className="col-lg-3 col-md-12 mb-4 mb-lg-0">
                          <Link to={`/product/${product.product._id}`}>
                            <div
                              className="bg-image hover-overlay hover-zoom ripple rounded"
                              data-mdb-ripple-color="light"
                            >
                              <img
                                src={url + `/${product.product.photos[0]}`}
                                className="w-100"
                                alt={product.product.name}
                              />
                            </div>
                          </Link>
                        </div>

                        <div className="cart_item_subdetails col-lg-5 col-md-6 mb-4 mb-lg-0">
                          <Link to={`/product/${product.product._id}`}>
                            <h5>{product.product.name}</h5>
                          </Link>
                          <p>
                            <ReactMarkdown>
                              {truncateDescription(product.product.description)}
                            </ReactMarkdown>
                          </p>
                        </div>

                        <div className="price_remove_cart col-lg-4 col-md-6 mb-4 mb-lg-0">
                          <p className="cart_product_price">
                            <strong>
                              Price: &#8377; {product.product.price}
                            </strong>
                          </p>
                          <p>
                            <strong>
                              Status of Your Order - {order.status}
                            </strong>
                          </p>
                          <p>
                            <strong>
                              Ordered On -
                              {moment(order.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </strong>
                          </p>
                          <p>
                            <strong>Email: {order.buyer.email}</strong>
                          </p>
                          <p>
                            <strong>
                              Payment Status:
                              {order.razorpayData.status}
                            </strong>
                          </p>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Orders;
