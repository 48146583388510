import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import AdminMenu from "../../components/Layout/AdminMenu";
import toast from "react-hot-toast";
import "../../styles/Dashboard.scss";
import axios from "axios";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useURL } from "../../context/url";

const { Option } = Select;

const CreateBanner = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [photos, setPhotos] = useState([]); // Change to array for multiple images
  const [prodLink, setProdLink] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [url, setURL] = useURL();

  //create product function
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const bannerData = new FormData();
      bannerData.append("name", name);
      bannerData.append("prodLink", prodLink);
      bannerData.append("dimensions", dimensions);


      // Append each photo to FormData
      photos.forEach((photo) => {
        bannerData.append("photos", photo);
        console.log([...bannerData]);
      });

      const { data } = await axios.post(
        url + "/api/v1/hero/create-hero",
        bannerData
      );
      console.log(...bannerData);
      console.log(data);
      if (data?.success) {
        toast.success(data?.message);
      } else {
        toast.error("Banner Created Successfully");
        navigate("/dashboard/admin/heros");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // Update photo state when input changes
  const handlePhotoChange = (e) => {
    setPhotos([...e.target.files]); // Spread existing photos and add new ones
  };

  return (
    <Layout title={"Dashboard - Create Product"}>
      <div className="order_page container-fluid p-0 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9 p-5 dashboard_right">
            <div className="dashboard_right_sub">
              <h1 className="sec-heading">Create Banner</h1>
              <div className="m-1">
                <div className="mb-3">
                  <label className="btn btn-outline-secondary col-md-12">
                    Upload Banner
                    <input
                      type="file"
                      name="photos"
                      accept="image/*"
                      onChange={handlePhotoChange}
                      multiple // Allow multiple files to be selected
                      hidden
                    />
                  </label>
                </div>
                {/* Display selected photos */}
                <div className="mb-3">
                  {photos.map((photo, index) => (
                    <div key={index} className="text-center">
                      <img
                        src={URL.createObjectURL(photo)}
                        alt={`product_photo_${index}`}
                        height={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ))}
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={name}
                    placeholder="write a name"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={prodLink}
                    placeholder="write product link"
                    className="form-control"
                    onChange={(e) => setProdLink(e.target.value)}
                  />
                </div>
                <Select
                  bordered={false}
                  placeholder="Select Screen"
                  size="large"
                  showSearch
                  className="form-select mb-3"
                  onChange={(value) => {
                    setDimensions(value);
                  }}
                >
                  <Option value="Computer">Computer Screen</Option>
                  <Option value="Mobile">Mobile Screen</Option>
                </Select>
                <div className="form-btns">
                  <div className="mb-3">
                    <button
                      className="btn btn-secondary"
                      onClick={handleCreate}
                      disabled={!name || !prodLink || photos.length === 0}
                    >
                      CREATE Banner
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateBanner;
