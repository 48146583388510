import React from "react";
import Layout from "../components/Layout/Layout";
import Owner from "../images/owner.jpg";

function Management() {
  return (
    <Layout title={"Management"}>
      <div className="row contactus">
        <div className="col-md-4 ">
          <img
            src={Owner}
            alt="contactus"
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-md-6 contactus_para">
          <p className="">
            Meet Mr. Sushil Mohan Yadav, the dynamic force behind Sumoyaa Sales
            Corporation and Aquafresh Water Purifiers. A versatile entrepreneur
            and visionary leader, Mr. Yadav's passion for business extends into
            the realms of entertainment and media production. As the owner of
            Sumoyaa Bhojpuri, Sumoyaa Music, Sumoyaa Bhakti, Sumoyaa Hitz,
            Sumoyaa Vlogs, Sumoyaa Movies, and Sumoyaa Gaonwala, he showcases
            his multifaceted talents and commitment to delivering engaging
            content to audiences worldwide.
          </p>
          <p>
            At the helm of Sumoyaa Gaonwala Productions, located in the bustling
            heart of Andheri West, Mr. Yadav brings his creative vision to life,
            shaping the landscape of entertainment with his innovative approach.
            Alongside him stands Mrs. Bhupinder Kaur Yadav, his devoted wife and
            Managing Director, who tirelessly works to serve the needs of their
            customers and uphold the company's reputation for excellence.
          </p>
          <p>
            Together, Mr. and Mrs. Yadav epitomize dedication, creativity, and
            entrepreneurial spirit, driving Sumoyaa Sales Corporation and its
            affiliated ventures to new heights of success. With a firm
            commitment to customer satisfaction and a passion for innovation,
            they continue to redefine industry standards and inspire others with
            their remarkable journey.
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default Management;
