import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Routes, Route, useLocation } from "react-router-dom";

import { useHeight } from "../../context/HeaderHeightContext";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
const Layout = ({ children, title, description, keywords, author }) => {
  const { headerRef } = useHeight();
  const location = useLocation();
  const noHeaderFooterPaths = [
    "/login",
    "/register",
    "/resetpassword",
    "/forgot-password",
  ];

  const shouldShowHeaderFooter = !noHeaderFooterPaths.includes(
    location.pathname
  );


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      {/* <header ref={headerRef}> */}
      {shouldShowHeaderFooter && <Header />}
      {/* </header> */}
      <main style={{ minHeight: "70vh" }}>
        <Toaster />

        {children}
      </main>
      {shouldShowHeaderFooter && <Footer />}
    </div>
  );
};

Layout.defaultProps = {
  title: "Aquafresh",
  description: "E-commerce website",
  keywords: "RO, stoves",
  author: "PrabhatxSingh",
};

export default Layout;
