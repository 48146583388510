import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../context/auth";
import toast from "react-hot-toast";
import { useURL } from "../context/url";
import { useCart } from "../context/cart";
import Product from "./Product";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; 

import "../styles/ProductDetailsStyles.scss";

const ProductDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [url, setURL] = useURL();
  const [cart, setCart] = useCart();

  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (params?.slug) getProduct();
  }, [params?.slug]);

  // Get product details
  const getProduct = async () => {
    try {
      const { data } = await axios.get(
        url + `/api/v1/product/get-product/${params.slug}`
      );
      setProduct(data?.product);
      getSimilarProduct(data?.product._id, data?.product.category._id);
    } catch (error) {
      console.log(error);
    }
  };

  // Get similar products
  const getSimilarProduct = async (pid, cid) => {
    try {
      const { data } = await axios.get(
        url + `/api/v1/product/related-product/${pid}/${cid}`
      );
      setRelatedProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Layout>
      <div className="single-product-main-content">
        <div className="layout">
          <div className=" single-product-page">
            <div className="left">
              <div
                id="carouselMDExample"
                className="carousel slide carousel-fade"
                data-bs-ride="carousel"
              >
                {/* Slides */}
                <div className="carousel-inner mb-5 shadow-1-strong rounded-3">
                  {product?.photos?.map((photo, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${
                        currentSlide === index ? "active" : ""
                      }`}
                    >
                      {/* <img
                        src={url + `/${photo}`}
                        className="d-block"
                        alt={`Slide ${index + 1}`}
                      /> */}
                      <LazyLoadImage
                        src={url + `/${photo}`}
                        className="d-block"
                        alt={`Slide ${index + 1}`}
                        effect="blur"
                        placeholderSrc="path_to_loading_spinner.gif" // Replace with your loading spinner path
                      />
                    </div>
                  ))}
                </div>
                {/* Slides */}

                {/* Controls */}
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselMDExample"
                  data-bs-slide="prev"
                  onClick={() =>
                    handleSlideChange(
                      (currentSlide - 1 + product.photos.length) %
                        product.photos.length
                    )
                  }
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselMDExample"
                  data-bs-slide="next"
                  onClick={() =>
                    handleSlideChange(
                      (currentSlide + 1) % product.photos.length
                    )
                  }
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
                {/* Controls */}

                {/* Thumbnails */}
                <div
                  className="carousel-indicators"
                  style={{ marginBottom: "-20px" }}
                >
                  {product?.photos?.map((photo, index) => (
                    <button
                      key={index}
                      type="button"
                      data-bs-target="#carouselMDExample"
                      data-bs-slide-to={index}
                      className={
                        currentSlide === index
                          ? "active carousel-prev"
                          : "carousel-prev"
                      }
                      onClick={() => handleSlideChange(index)}
                    >
                      {/* <img
                        src={url + `/${photo}`}
                        className="product_small_img d-block shadow-1-strong rounded img-fluid"
                        alt={`Thumbnail ${index + 1}`}
                      /> */}
                      <LazyLoadImage
                        src={url + `/${photo}`}
                        className="product_small_img d-block shadow-1-strong rounded img-fluid"
                        alt={`Thumbnail ${index + 1}`}
                        effect="blur"
                        placeholderSrc="path_to_loading_spinner.gif" // Replace with your loading spinner path
                      />
                    </button>
                  ))}
                </div>
                {/* Thumbnails */}
              </div>
            </div>
            <div className="right product-details-info">
              <div className="name">{product?.name}</div>
              <div className="cut-price">&#8377; {product?.oldPrice}</div>
              <div className="price">&#8377;{product?.price}</div>
              {/* <div className="specs_heading">Product Specifications</div>
              <div className="desc">
                <ReactMarkdown>{product?.description}</ReactMarkdown>
              </div> */}
              <div className="specs_heading">
                Product Specifications
                <IoIosArrowDropdownCircle
                  onClick={toggleDropdown}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className={`desc ${isOpen ? "open" : ""}`}>
                <ReactMarkdown>{product?.description}</ReactMarkdown>
              </div>
              <div className="cart-buttons">
                <button
                  className="btn btn-secondary ms-1"
                  onClick={() => {
                    setCart([...cart, product]);
                    localStorage.setItem(
                      "cart",
                      JSON.stringify([...cart, product])
                    );
                    toast.success("Item Added to cart");
                  }}
                >
                  Add to Cart
                </button>
                <Link to="/cart">
                  <button className="btn btn-dark ms-1">Proceed to Pay</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content">
          <div className="layout">
            <div className="sec-heading">Similar Products</div>
            {relatedProducts.length < 1 && (
              <p className="text-center">No Similar Products found</p>
            )}
            <div className="product_cat d-flex flex-wrap">
              {relatedProducts?.map((product) => (
                <div
                  className="product-card"
                  key={product._id}
                  onClick={() => navigate(`/product/${product.slug}`)}
                >
                  <Product key={product._id} product={product} />

                  {/* //   <div className="thumbnail">
                //     <img
                //       src={url + `/${p.photos[1]}`}
                //       className="card-img-top"
                //       alt={p.name}
                //     />
                //   </div>
                //   <div className="prod-details">
                //     <div className="name">
                //       <h5>{p?.name}</h5>
                //     </div>
                //     <div className="prod-details_lower">
                //       <div className="price">&#8377;{p?.price}</div>
                //       <button
                //         className="btn btn-dark btn-sm"
                //         onClick={() => navigate(`/product/${p.slug}`)}
                //       >
                //         BUY
                //       </button>
                //     </div>
                //   </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductDetails;
