import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/auth";
import { HeaderHeightProvider } from './context/HeaderHeightContext';

import { SearchProvider } from "./context/search";
import { CartProvider } from "./context/cart";
import { UrlProvider } from "./context/url";
import "antd/dist/reset.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HeaderHeightProvider>

  <UrlProvider>
    <AuthProvider>
      <SearchProvider>
        <CartProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CartProvider>
      </SearchProvider>
    </AuthProvider>
  </UrlProvider>
  </HeaderHeightProvider>

);

