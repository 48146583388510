
import React from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import blur effect for lazy loading
import { useURL } from "../context/url";
import "../styles/Product.scss";
const Product = ({ product }) => {
  const navigate = useNavigate();
  const [url] = useURL();

  return (
    <div>
      <div className="thumbnail">
        {/* <img
          src={url + `/${product.photos[1]}`}
          className="card-img-top"
          alt={product.name}
        /> */}
        <LazyLoadImage
          src={url + `/${product.photos[1]}`}
          className="d-block w-100"
          alt={product.name}
          effect="blur"
          placeholderSrc="path_to_loading_spinner.gif" // Replace with your loading spinner path
        />
      </div>

      <div className="prod-details">
        <div className="name">
          <p>{product.name}</p>
        </div>

        <div className="prod-details_lower">
          <div className="price">&#8377; {product.price}</div>
          <div className="price">MRP incl. of all taxes</div>

          {/* <button
            className="btn btn-dark btn-sm"
            onClick={() => navigate(`/product/${product.slug}`)}
          >
            BUY
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Product;
