import React from "react";
import Layout from "../components/Layout/Layout";

const About = () => {
  return (
    <Layout title={"About us"}>
      <div className="row contactus">
        <div className="col-md-4 ">
          <img
            src="/images/about.jpeg"
            alt="contactus"
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-md-6 contactus_para">
          <p className="">
            Welcome to Aquafresh Water Purifiers, a trusted name in clean water
            solutions since our establishment in 2008. As a registered company
            born in India, we pride ourselves on offering a comprehensive range
            of products designed to enhance the quality of life for our
            customers.
          </p>
          <p>
            At Aquafresh Water Purifiers, we understand the importance of clean
            and safe water for you and your loved ones. That's why we specialize
            in a variety of water purification systems, ensuring that every drop
            you consume is free from impurities and contaminants. From advanced
            purifiers to sleek cooktops and efficient geysers, our products are
            engineered with innovation and quality in mind.
          </p>
          <p>
            Our commitment to excellence extends to our signature line, Pzone
            Ro, Classic Aquafresh Ro and Aquafresh Cooktop ( Registered ) a
            testament to our dedication to cutting-edge technology and superior
            performance.
          </p>
          <p>
            With Aquafresh Water Purifiers, you can trust that your water
            purification needs are in capable hands. Join us in our mission to
            make clean water accessible to all, and experience the Aquafresh
            difference today.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default About;
