import React, { useState, useEffect } from "react";
import { useURL } from "../context/url";
import { useNavigate, Link } from "react-router-dom";
import "../styles/Banner.scss";
import axios from "axios";
import toast from "react-hot-toast";

const Banner = () => {
  const navigate = useNavigate();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [url, setURL] = useURL();
  const [banners, setBanners] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [loadedImages, setLoadedImages] = useState(new Set());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getAllBanners();
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % banners.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [banners.length]);

  const getAllBanners = async () => {
    try {
      const endpoint = isMobile
        ? "/api/v1/hero/get-heros/Mobile"
        : "/api/v1/hero/get-heros/Computer";
      const { data } = await axios.get(url + endpoint);
      setBanners(data.banners);
      console.log(data.banners);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => new Set(prevLoadedImages).add(index));
  };

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="hero-banner">
      <div
        id="carouselMDExample"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-inner mb-5 shadow-1-strong">
          {banners.map((banner, index) => (
            <div
              key={banner._id}
              className={`carousel-item ${
                currentSlide === index ? "active" : ""
              }`}
            >
              <img
                src={url + `/${banner.photos[0]}`}
                className={`d-block w-100 ${
                  loadedImages.has(index) ? "" : "banner-blur"
                }`}
                alt={`Slide ${index + 1}`}
                onClick={() => {
                  navigate(banner.prodLink);
                }}
                onLoad={() => handleImageLoad(index)}
                loading="lazy"
              />
            </div>
          ))}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselMDExample"
          data-bs-slide="prev"
          onClick={() =>
            handleSlideChange(
              (currentSlide - 1 + banners.length) % banners.length
            )
          }
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselMDExample"
          data-bs-slide="next"
          onClick={() => handleSlideChange((currentSlide + 1) % banners.length)}
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Banner;
