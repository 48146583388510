import React from "react";
import { useAuth } from "../../context/auth";
import { NavLink } from "react-router-dom";

const AdminMenu = () => {
  const [auth, setAuth] = useAuth();

  return (
    <>
      <div className="text-center mt-4 dashboard-menu">
        <div className="list-group">
          <NavLink
            to={`/dashboard/${auth?.user?.role === 1 ? "admin" : "user"}`}
            className="list-group-item list-group-item-action"
          >
            <h5>Admin Panel</h5>
          </NavLink>
          <NavLink
            to="/dashboard/admin/create-category"
            className="list-group-item list-group-item-action"
          >
            Create Category
          </NavLink>
          <NavLink
            to="/dashboard/admin/create-product"
            className="list-group-item list-group-item-action"
          >
            Create Product
          </NavLink>
          <NavLink
            to="/dashboard/admin/create-hero"
            className="list-group-item list-group-item-action"
          >
            Create Banner
          </NavLink>
          <NavLink
            to="/dashboard/admin/create-video"
            className="list-group-item list-group-item-action"
          >
            Create Video
          </NavLink>
          <NavLink
            to="/dashboard/admin/heros"
            className="list-group-item list-group-item-action"
          >
            Banners
          </NavLink>
          <NavLink
            to="/dashboard/admin/products"
            className="list-group-item list-group-item-action"
          >
            Products
          </NavLink>
          <NavLink
            to="/dashboard/admin/orders"
            className="list-group-item list-group-item-action"
          >
            Orders
          </NavLink>
          <NavLink
            to="/dashboard/admin/users"
            className="list-group-item list-group-item-action"
          >
            Users
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default AdminMenu;
