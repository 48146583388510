import React, { createContext, useState, useEffect,useContext, useRef } from 'react';

const HeaderHeightContext = createContext();

export const HeaderHeightProvider = ({ children }) => {
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  return (
    <HeaderHeightContext.Provider value={{ headerHeight, headerRef }}>
      {children}
    </HeaderHeightContext.Provider>
  );
};

export const useHeight = () => useContext(HeaderHeightContext);
