import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "./../../components/Layout/Layout";
import { useURL } from "../../context/url";
import toast from "react-hot-toast";

import "../../styles/Dashboard.scss";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [url] = useURL();

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(url + "/api/v1/auth/all-users");
      setLoading(false);
      setUsers(data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching users:", error);
    }
  };

  const searchUsers = async () => {
    if (!searchQuery.trim()) {
      fetchUsers();
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.get(
        `${url}/api/v1/auth/search-users?query=${searchQuery}`
      );
      setLoading(false);
      setUsers(data.users);
      if (data.users.length === 0) {
        toast.success("");
        
      }
    } catch (error) {
      setLoading(false);
      console.error("Error searching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Layout title={"Dashboard - All Users"}>
      <div className="order_page container-fluid p-0 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9 p-5 dashboard_right">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                placeholder="Search by name, address, phone no. or email"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                class="input-group-text"
                id="inputGroup-sizing-default"
                onClick={searchUsers}
              >
                Search Users
              </button>
            </div>
            <div className="dashboard_right_sub">
              <h1 className="sec-heading">All Users</h1>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Phone</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.email}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td>{user.phone}</td>
                        <td>{user.address}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Users;
