import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import About from "./pages/About.jsx";
import AuthorizeRating from "./pages/AuthorizeRating.jsx";
import Management from "./pages/Management.jsx";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ShippingPolicy from "./pages/ShippingPolicy.js";
import Pagenotfound from "./pages/Pagenotfound";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/user/Dashboard";
import PrivateRoute from "./components/Routes/Private";
import ForgotPasssword from "./pages/Auth/ForgotPasssword";
import AdminRoute from "./components/Routes/AdminRoute";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import CreateCategory from "./pages/Admin/CreateCategory";
import CreateProduct from "./pages/Admin/CreateProduct";
import Users from "./pages/Admin/Users";
import Orders from "./pages/user/Orders";
import Profile from "./pages/user/Profile";
import Products from "./pages/Admin/Products";
import UpdateProduct from "./pages/Admin/UpdateProduct";
import Search from "./pages/Search";
import ProductDetails from "./pages/ProductDetails";
import Categories from "./pages/Categories";
import CategoryProduct from "./pages/CategoryProduct";
import CartPage from "./pages/CartPage";
import AdminOrders from "./pages/Admin/AdminOrders";
import ScrollToTopOnMount from "./pages/ScrollToTopOnMount.js";


import Forgot from "./pages/Auth/Forgot";
import ResetPasssword from "./pages/Auth/Reset";
import CreateBanner from "./pages/Admin/CreateBanner";
import Banners from "./pages/Admin/Banners";
import Entertainment from "./pages/Entertainment.jsx";
import CreateVideo from "./pages/Admin/CreateVideo.js";
import OrderDetails from "./pages/Admin/OrderDetails.jsx";
import ReturnPolicy from "./pages/ReturnPolicy.js";
function App() {
  return (
    <>
      <ScrollToTopOnMount />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:slug" element={<ProductDetails />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/category/:slug" element={<CategoryProduct />} />
        <Route path="/search" element={<Search />} />
        <Route path="/dashboard" element={<PrivateRoute />}>
          {/* <Route path="user" element={<Dashboard />} /> */}
          <Route path="user" element={<Profile />} />
          <Route path="user/orders" element={<Orders />} />
        </Route>
        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/create-category" element={<CreateCategory />} />
          <Route path="admin/create-product" element={<CreateProduct />} />
          <Route path="admin/create-hero" element={<CreateBanner />} />
          <Route path="admin/create-video" element={<CreateVideo />} />
          <Route path="admin/heros" element={<Banners />} />

          <Route path="admin/product/:slug" element={<UpdateProduct />} />
          <Route path="admin/products" element={<Products />} />
          <Route path="admin/users" element={<Users />} />
          <Route path="admin/orders" element={<AdminOrders />} />
          <Route path="admin/order/:id" element={<OrderDetails />} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route path="/resetpassword" element={<ResetPasssword />} />
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/ratings" element={<AuthorizeRating />} />
        <Route path="/management" element={<Management />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/shipping-policy" element={<ShippingPolicy />} />
        <Route path="/refund-policy" element={<ReturnPolicy />} />
        <Route path="/entertainment" element={<Entertainment />} />

        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}

export default App;
