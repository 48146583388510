import React, { useState, useEffect } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import { useURL } from "../../context/url";

import toast from "react-hot-toast";
import axios from "axios";
const Profile = () => {
  //context
  const [auth, setAuth] = useAuth();
  const [url, setURL] = useURL();

  //state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  //get user data
  useEffect(() => {
    const { email, name, phone, address } = auth?.user;
    setName(name);
    setPhone(phone);
    setEmail(email);
    setAddress(address);
  }, [auth?.user]);

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
        toast.error("Password and Confirm Password are not same!");
      return;
    }
    try {
      const { data } = await axios.put(url + "/api/v1/auth/profile", {
        name,
        email,
        password,
        phone,
        address,
      });
      if (data?.errro) {
        toast.error(data?.error);
      } else {
        setAuth({ ...auth, user: data?.updatedUser });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data.updatedUser;
        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Your Profile"}>
      <div className="order_page container-fluid p-0 dashboard">
        <div className="row">
          <div className="col-md-3">
            <UserMenu />
          </div>

          <div className="p-5 dashboard_right col-md-9">
            <div className="dashboard_right_sub">
              <h1 className="sec-heading">Profile</h1>

              <div className="w-full form-container">
                <form className="form-div">
                  <div className="row mb-3">
                    <div className="col-lg-6 column-gap">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        id="name"
                        placeholder="Enter Your Name"
                        autoFocus
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        id="email"
                        placeholder="Enter Your Email"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6 column-gap">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="form-control"
                        id="phone"
                        placeholder="Enter Your Phone"
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="address">Address</label>
                      <textarea
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="form-control"
                        id="address"
                        placeholder="Enter Your Address"
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 column-gap">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                        id="password"
                        placeholder="Enter Your Password"
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="form-control"
                        id="confirmPassword"
                        placeholder="Confirm Your Password"
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                  <div className="form-btns">
                    {isEditing ? (
                      <button
                        type="submit"
                        className="btn btn-dark"
                        onClick={handleSubmit}
                      >
                        UPDATE
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleEditClick}
                      >
                        EDIT
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
