import React from "react";
import Layout from "../components/Layout/Layout";

const ReturnPolicy = () => {
  return (
    <Layout title={"Shipping Policy"}>
      <div className="row contactus">
        <div className="col-md-12 contactus_para">
          <h1>Sumoyaa Sales Corporation return and refund policy </h1>
          <div>
            <ol>
              <li>
                Sumoyaa Sales Corporation warrants all new products manufacured
                and sold under brand name “Aquafresh Water Purifiers” & “Pzone”
                , “Classic Aquafresh” , “online” are to be free from defects in
                material and workmanship (manufacturing defect).
              </li>
              <li>
                Sumoyaa Sales Corporation provides warranty for its manufactured
                products as per warranty conditions (warranty card) placed along
                with products.
              </li>
              <li>
                Product once sold and installed/used cannot be returned/
                refunded.
              </li>{" "}
              <li>
                There may be instances when you need to cancel the order. You
                may cancel your order before your product has shipped. No
                cancellation request will be processed after your product has
                shipped.
              </li>{" "}
              <li>
                To cancel an order (full payment made through online payment
                gateway) on sumoyaa.in, purchaser has to call our customer care
                department or E-Mail it to sumoyaaaquafresh@sumoyaa.in (before
                it is shipped) to initiate cancellation/refund with transaction
                details
              </li>
              <li>
                Sumoyaa Sales Corporation will refund to you the cost. The
                refund will be made to the same payment option(s) originally
                used to pay for the order. Please note we will not be able to
                return the same by cheque or cash.
              </li>
            </ol>
          </div>
          <div>
            Before initiating a product cancellation, it's crucial to understand
            the applicable policies to avoid any issues. To ensure our customers
            experience hassle-free service when canceling a product and
            obtaining a refund, Sumoyaa Sales Corporation provides comprehensive
            information about order cancellation and refund policies. Whether
            you need to know the time frame within which you can cancel an order
            or want to learn more about Kent's cancellation policy, all the
            necessary details are available.
          </div>
          <div className="mt-4">
            <h6>The returns shall not be accepted in the following cases:</h6>
            <ol>
              <li>
                Product is damaged due to misuse/overuse, or any other physical
                damage has been caused to the product with force.
              </li>
              <li>Product is altered by customer</li>
              <li>
                If request is initiated after 7 business days of order delivery.{" "}
              </li>
            </ol>
          </div>

          <div>
            <h3>Refund Policy</h3>
            <ol>
              <li>
                In the event of a return or cancellation, the refund will be
                processed after the product is returned to us and its quality
                has been verified by us.
              </li>{" "}
              <li>
                PREPAID ORDERS - the refund will be credited to the original
                payment method. Depending on the payment method, it may take
                7-15 business days for the refund to appear in your account.
                Please note that the timing is dependent on the payment gateway.
              </li>{" "}
              <li>
                Cash on Delivery (COD) orders - the refund amount will be
                transferred to your preferred digital payment method after the
                returned package is verified. If you have any issues regarding a
                COD refund, please contact our call center at 9560656900 or
                email us at : sumoyaaaquafresh@sumoyaa.in
              </li>{" "}
              <li>
                Refund will be initiated once the request is processed and in
                case you're still contacted for delivery, please refuse the
                delivery.
              </li>
            </ol>
          </div>
          <h5>Sincerely,</h5>
          <h5>The Sumoyaa Sales Corporation Team</h5>
        </div>
      </div>
    </Layout>
  );
};

export default ReturnPolicy;
