import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import AdminMenu from "../../components/Layout/AdminMenu";
import toast from "react-hot-toast";
import "../../styles/Dashboard.scss";
import axios from "axios";
import CategoryForm from "../../components/Form/CategoryForm";
import { useURL } from "../../context/url";

import { Modal } from "antd";
import VideoForm from "../../components/Form/VideoForm";
const CreateVideo = () => {
  const [videos, setVideos] = useState([]);
  const [name, setName] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [url, setURL] = useURL();
  //handle Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(url + "/api/v1/video/create-video", {
        name,
        videoLink,
      });
      if (data?.success) {
        toast.success(`${name} is created`);
        getAllVideos();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      // toast.error("somthing went wrong in input form");
    }
  };

  //get all cat
  const getAllVideos = async () => {
    try {
      const { data } = await axios.get(url + "/api/v1/video/get-videos");
      console.log(data)
      if (data?.success) {
        setVideos(data?.videos);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something wwent wrong in getting catgeory");
    }
  };

  useEffect(() => {
    getAllVideos();
  }, []);

  //update video
  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const { data } = await axios.put(
  //       url + `/api/v1/video/update-video/${selected._id}`,
  //       { name: updatedName }
  //     );
  //     if (data?.success) {
  //       toast.success(`${updatedName} is updated`);
  //       setSelected(null);
  //       setUpdatedName("");
  //       setVisible(false);
  //       getAllVideos();
  //     } else {
  //       toast.error(data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  //delete video
  const handleDelete = async (pId) => {
    try {
      const { data } = await axios.delete(
        url + `/api/v1/video/delete-video/${pId}`
      );
      if (data.success) {
        toast.success(`video is deleted`);

        getAllVideos();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Somtihing went wrong");
    }
  };
  return (
    <Layout title={"Dashboard - Create Category"}>
      <div className="order_page container-fluid p-0 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9 p-5 dashboard_right">
            <div className="dashboard_right_sub">
              <h1 className="sec-heading">Manage Video Links</h1>
              <div className="p-2">
                <VideoForm
                  handleSubmit={handleSubmit}
                  name={name}
                  setName={setName}
                  videoLink={videoLink}
                  setVideoLink={setVideoLink}
                />
              </div>
              <div className="w-100">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Video Link</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {videos?.map((video) => (
                      <>
                        <tr key={video._id}>
                          <td>{video.name}</td>
                          <td>{video.videoLink}</td>
                          <td>
                            {/* <button
                              className="btn btn-secondary ms-2"
                              onClick={() => {
                                setVisible(true);
                                setUpdatedName(video.name);
                                setSelected(video);
                              }}
                            >
                              Edit
                            </button> */}
                            <button
                              className="btn btn-danger ms-2"
                              onClick={() => {
                                handleDelete(video._id);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <Modal
                onCancel={() => setVisible(false)}
                footer={null}
                visible={visible}
              >
                <CategoryForm
                  value={updatedName}
                  setValue={setUpdatedName}
                  handleSubmit={handleUpdate}
                />
              </Modal> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateVideo;
