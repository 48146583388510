import { useState, useContext, createContext } from "react";

const UrlContext = createContext();
const UrlProvider = ({ children }) => {
  const [url, setURL] = useState(
    "https://api.sumoyaa.in"
    // "http://localhost:8080"
    // "https://sumoyaa.onrender.com"
    // "http://127.0.0.1:8080"
  );

  return (
    <UrlContext.Provider value={[url, setURL]}>{children}</UrlContext.Provider>
  );
};

// custom hook
const useURL = () => useContext(UrlContext);

export { useURL, UrlProvider };
