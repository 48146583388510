import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import axios from "axios";
import toast from "react-hot-toast";
import { useURL } from "../context/url";
import "../styles/Entertainment.scss";
import Layout from "../components/Layout/Layout";
import VideoPlayer from "./VideoPlayer";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa"; // Import FontAwesome icons

function Entertainment() {
  const [videos, setVideos] = useState([]);
  const [url, setURL] = useURL();

  //get all cat
  const getAllVideos = async () => {
    try {
      const { data } = await axios.get(url + "/api/v1/video/get-videos");
      console.log(data);
      if (data?.success) {
        setVideos(data?.videos);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting category");
    }
  };

  useEffect(() => {
    getAllVideos();
  }, []);

  return (
    <Layout title={"Sumoyaa Entertainment"}>
      <div className="entertainment">
        <div className="entertainment_titles">
          <h2>Welcome to World of</h2>
          <h1>Sumoyaa Entertainment</h1>
        </div>
        <div className="container">
          <div className="row mb-4">
            {videos.slice(0, 3).map((video, index) => (
              <div key={index} className="col-lg-4 mb-4">
                <div className="video-card m-auto shadow-lg">
                  <VideoPlayer videoId={video.videoLink} />
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {videos.slice(3, 5).map((video, index) => (
              <div key={index} className="col-lg-6  mb-4">
                <div className="video-card m-auto shadow-lg">
                  <VideoPlayer videoId={video.videoLink} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="entertainment_footer">
          <h2>Click to Watch</h2>
        </div>
        <div className="social-icons">
          <Link
            to="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF size={25} />
          </Link>
          <Link
            to="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter size={25} />
          </Link>
          <Link
            to="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={25} />
          </Link>
          <Link
            to="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn size={25} />
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default Entertainment;
