import React from "react";
import Layout from "./../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { useURL } from "../context/url";
import { useSearch } from "../context/search";
import Product from "./Product";
import "../styles/Search.scss";
const Search = () => {
  const navigate = useNavigate();
  const [values, setValues] = useSearch();
  const [url, setURL] = useURL();


  return (
    <Layout title={"Search results"}>
      <div className="main-content search-page">
        <div className="layout">
          <div className="sec-heading">Search Results</div>

          <h6>{values?.results.length < 1 ? "No Products Found" : ``}</h6>
          <div className="product_cat d-flex flex-wrap mt-4">
            {values?.results.map((product) => (
              <div
              className="product-card"
              key={product._id}
              onClick={() => navigate(`/product/${product.slug}`)}
            >
          <Product key={product._id} product={product} />

            {/* //   <div className="thumbnail">
            //     <img
            //       src={url + `/${p.photos[1]}`}
            //       className="card-img-top"
            //       alt={p.name}
            //     />
            //   </div>
            //   <div className="prod-details">
            //     <div className="name">
            //       <h5>{p?.name}</h5>
            //     </div>
            //     <div className="prod-details_lower">
            //       <div className="price">&#8377;{p?.price}</div>
            //       <button
            //         className="btn btn-dark btn-sm"
            //         onClick={() => navigate(`/product/${p.slug}`)}
            //       >
            //         BUY
            //       </button>
            //     </div>
            //   </div> */}

            </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
