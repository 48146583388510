import React, { useState, useEffect } from "react";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "../../components/Layout/Layout";
import "../../styles/Dashboard.scss";
import "../../styles/Products.scss";
import axios from "axios";
import { useURL } from "../../context/url";
import toast from "react-hot-toast";
const Banners = () => {
  const [banners, setbanners] = useState([]);

  const [url, setURL] = useURL();

  //getall banners
  const getAllBanners = async () => {
    try {
      const { data } = await axios.get(url + "/api/v1/hero/get-heros");
      console.log(data);

      setbanners(data.banners);
      // console.log(data.banners[0].photos[0]);
    } catch (error) {
      console.log(error);
      toast.error("Someething Went Wrong");
    }
  };

  const handleDelete = async (id) => {
    try {
      let answer = window.confirm(
        "Are you sure you want to delete this product?"
      );
      if (!answer) return;
      const { data } = await axios.delete(
        url + `/api/v1/hero/delete-hero/${id}`
      );
      if (data.message) {
        toast.success(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  //lifecycle method
  useEffect(() => {
    getAllBanners();
  }, []);
  return (
    <Layout>
      <div className="order_page container-fluid p-0 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9 p-5 dashboard_right">
            <div className="dashboard_right_sub">
              <h1 className="sec-heading">All Banners List</h1>
              <div className="product_cat d-flex flex-wrap">
                {banners?.map((banner) => (
                  <div key={banner._id} className="card banner-link banner-card">
                    <div className="thumbnail">
                      <img
                        src={url + `/${banner.photos[0]}`}
                        className="card-img-top"
                        alt={banner.name}
                      />
                    </div>

                    <div className="banner-details">
                      <h5>Link - {banner?.prodLink}</h5>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(banner._id)}
                      >
                        DELETE PRODUCT
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Banners;
