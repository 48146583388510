import React from "react";
import Layout from "../components/Layout/Layout";

const Contact = () => {
  return (
    <Layout title={"Contact Us"}>
      <div className="row contactus ">
        <div className="col-md-12 contactus_para">
          <h1>Shipping Policy</h1>
          <p>
            At Sumoyaa Sales Corporation, the parent company of Aquafresh Water
            Purifiers, we are dedicated to delivering your orders with utmost
            sincerity and efficiency. We strive to ensure that your Aquafresh
            Water Purifier reaches you within the standard delivery timeframe of
            15 days from the date of purchase.
          </p>
          <div>
            <h6>Shipping Timeframe</h6>
            <ul>
              <li>Our standard delivery period is within 15 days.</li>
              <li>
                The delivery period may be extended based on the location and
                availability of the product.
              </li>
              <li>
                We will make every effort to deliver your order as soon as
                possible.
              </li>
            </ul>
          </div>
          <div>
            <h6>Order Tracking</h6>
            <ul>
              <li>
                Once your order is dispatched, you will receive a confirmation
                email.
              </li>
              <li>Contact our customer service email for updates.</li>
            </ul>
          </div>
          <div>
            <h6>Delivery Delays</h6>
            <ul>
              <li>
                While we aim to adhere to the estimated delivery timeframe,
                unforeseen circumstances such as weather conditions,
                transportation delays, or regional holidays may impact delivery
                times.
              </li>
              <li>
                If there are significant delays, we will notify you promptly and
                provide updated delivery information.
              </li>
            </ul>
          </div>

          <div>
            <h6>Customer Service</h6>
            <ul>
              <li>
                If you have any questions or concerns regarding your order, our
                customer service email is available to assist you. Please
                contact us via
              </li>
            </ul>
          </div>

          <h5>Thank You for Choosing Aquafresh!</h5>
          <p>
            We are thankful that you chose us! and are committed to providing
            you with the best possible. Thank you for choosing Aquafresh Water
            Purifiers by Sumoyaa Sales Corporation. We look forward to serving
            you again in the future. For any further inquiries, please reach out
            to our support team. Your satisfaction is our priority!
          </p>

          <h5>Sincerely,</h5>
          <h5>The Sumoyaa Sales Corporation Team</h5>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
