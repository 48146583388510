import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import { useURL } from "../../context/url";
import moment from "moment";
import { Select } from "antd";
import { Link } from "react-router-dom";
import "../../styles/Dashboard.scss";
const { Option } = Select;

const AdminOrders = () => {
  const [status, setStatus] = useState([
    "Not Process",
    "Processing",
    "Shipped",
    "Delivered",
    "Cancel",
  ]);
  const [changeStatus, setChangeStatus] = useState("");
  const [orders, setOrders] = useState([]);
  const [auth] = useAuth();
  const [url] = useURL();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const getOrders = async () => {
    try {
      const { data } = await axios.get(url + "/api/v1/payment/orders");
      setOrders(data.orders);
    } catch (error) {
      console.log(error);
    }
  };

  const searchOrders = async () => {
    if (!searchQuery.trim()) {
      getOrders();
      return;
    }

    try {
      const { data } = await axios.get(
        `${url}/api/v1/payment/search-orders?query=${searchQuery}`
      );
      setOrders(data.orders);
      if (data.orders.length === 0) {
        toast.success("No orders found");
      }
    } catch (error) {
      console.error("Error searching orders:", error);
    }
  };

  const sortOrders = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedOrders = [...orders].sort((a, b) => {
      let aValue = a;
      let bValue = b;

      // Navigate to the nested value if the key contains dots (e.g., 'razorpay.created_at')
      key.split(".").forEach((k) => {
        aValue = aValue[k];
        bValue = bValue[k];
      });

      if (key === "razorpay.created_at") {
        aValue = moment.unix(aValue);
        bValue = moment.unix(bValue);
      }

      if (typeof aValue === "string") {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setOrders(sortedOrders);
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  return (
    <Layout title={"All Orders Data"}>
      <div className="order_page container-fluid p-0 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9 p-5 dashboard_right">
            <div className="dashboard_right_sub">
              <h1 className="sec-heading">All Orders</h1>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder="Search orders"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button
                  class="input-group-text"
                  id="inputGroup-sizing-default"
                  onClick={searchOrders}
                >
                  Search Orders
                </button>
              </div>
              <div className="table_div">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Actions</th>
                      <th
                        scope="col"
                        onClick={() => sortOrders("_id")}
                        style={{ cursor: "pointer" }}
                      >
                        id{" "}
                        {sortConfig.key === "_id"
                          ? sortConfig.direction === "asc"
                            ? "↑"
                            : "↓"
                          : ""}
                      </th>
                      <th
                        scope="col"
                        onClick={() => sortOrders("razorpay.status")}
                        style={{ cursor: "pointer" }}
                      >
                        Payment{" "}
                        {sortConfig.key === "razorpay.status"
                          ? sortConfig.direction === "asc"
                            ? "↑"
                            : "↓"
                          : ""}
                      </th>
                      <th
                        scope="col"
                        onClick={() => sortOrders("status")}
                        style={{ cursor: "pointer" }}
                      >
                        Status{" "}
                        {sortConfig.key === "status"
                          ? sortConfig.direction === "asc"
                            ? "↑"
                            : "↓"
                          : ""}
                      </th>

                      <th
                        scope="col"
                        onClick={() => sortOrders("razorpay.created_at")}
                        style={{ cursor: "pointer" }}
                      >
                        Date{" "}
                        {sortConfig.key === "razorpay.created_at"
                          ? sortConfig.direction === "asc"
                            ? "↑"
                            : "↓"
                          : ""}
                      </th>
                      <th scope="col">Email</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Phone no.</th>
                      <th scope="col">Products</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders?.map((order) => (
                      <tr key={order?._id}>
                        <td>
                          <Link to={`/dashboard/admin/order/${order?._id}`}>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-warning"
                            >
                              View
                            </button>
                          </Link>
                        </td>
                        <td>{order?._id}</td>
                        <td>{order?.razorpay?.status}</td>
                        <td>{order?.status}</td>
                        <td>
                          {moment
                            .unix(order?.razorpay?.created_at)
                            .format("MMMM Do YYYY, h:mm:ss a")}
                        </td>
                        <td>{order?.razorpay?.payments?.items[0]?.email}</td>
                        <td>
                          {order?.razorpay?.payments?.items[0]?.currency}
                          {order?.razorpay?.payments?.items[0]?.amount / 100}
                        </td>
                        <td>{order?.razorpay?.payments?.items[0]?.contact}</td>
                        <td>
                          <ul>
                            {order?.products?.map((elem) => (
                              <li key={elem.product._id}>
                                {elem.product.name}
                              </li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminOrders;
