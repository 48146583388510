import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import { useURL } from "../../context/url";
import moment from "moment";
import AdminMenu from "../../components/Layout/AdminMenu";
import ReactMarkdown from "react-markdown";
import toast from "react-hot-toast";


import { Select } from "antd";

const { Option } = Select;

const OrderDetails = () => {
  const [order, setOrder] = useState(null);
  const [razorpayData, setRazorpayData] = useState(null);
  const { id } = useParams();
  const [auth] = useAuth();
  const [url] = useURL();

 const getOrders = async () => {
   try {
     const { data } = await axios.get(
       `${url}/api/v1/payment/single-order?id=${id}`
     );
     setOrder(data.order);
     console.log(data.order);
       getPaymentStatus(data.order.payment.razorpay_order_id);
   } catch (error) {
     console.log(error);
   }
 };

  const getPaymentStatus = async (razorpay_order_id) => {
    try {
      const { data } = await axios.get(
        `${url}/api/v1/payment/single-razorpay-order/?id=${razorpay_order_id}`
      );
      console.log(data.response);
      setRazorpayData(data.response);
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrder = async (orderId, newStatus) => {
    if (window.confirm("Are you sure you want to update this order?")) {
      const updateCommand = newStatus;
      try {
        const { data } = await axios.post(url + "/api/v1/payment/update-order", {
          orderId,
          updateCommand,
        });
        if (data.success) {
          getOrders();
          toast.success("Order Updated Successfully");

        }
      } catch (error) {
        console.log(error);
      }
    };
  }

  const truncateDescription = (description) => {
    if (description.length > 125) {
      return description.substring(0, 125) + "...";
    }
    return description;
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  return (
    <Layout title={`Order Details - ${id}`}>
      <div className="order_page container-fluid p-0 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9 p-5 dashboard_right">
            <div className="dashboard_right_sub">
              <div className="sec-heading">Order ID: {razorpayData?.id}</div>
              <div className="card mb-4">
                <div className="d-flex justify-content-between card-header py-3">
                  <h5 className="mb-0">
                    Total Products - {order?.products?.length}
                  </h5>
                  <div className="d-flex align-items-center justify-content-center gap-4">
                    <h5 className="mb-0">
                      Amount - &#8377; {order?.payment?.amount}
                    </h5>
                    {order?.status !== "Refunded" && (
                      <Select
                        defaultValue={order?.status}
                        style={{ width: 120 }}
                        onChange={(value) => updateOrder(order?._id, value)}
                      >
                        <Option value="Not Process">Not Process</Option>
                        <Option value="Processing">Processing</Option>
                        <Option value="Shipped">Shipped</Option>
                        <Option value="Delivered">Delivered</Option>
                        <Option value="Cancelled">Cancelled</Option>
                        <Option value="Refunded">Refunded</Option>
                      </Select>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  {order?.products?.map((product, index) => (
                    <div className="row mb-4" key={product?.product?._id}>
                      <div className="col-lg-3 col-md-12 mb-4 mb-lg-0">
                        <Link to={`/product/${product?.product?._id}`}>
                          <div
                            className="bg-image hover-overlay hover-zoom ripple rounded"
                            data-mdb-ripple-color="light"
                          >
                            <img
                              src={url + `/${product?.product?.photos[0]}`}
                              className="w-100"
                              alt={product?.product?.name}
                            />
                          </div>
                        </Link>
                      </div>

                      <div className="cart_item_subdetails col-lg-5 col-md-6 mb-4 mb-lg-0">
                        <Link to={`/product/${product?.product?._id}`}>
                          <h5>{product?.product?.name}</h5>
                        </Link>
                        <p>
                          <ReactMarkdown>
                            {truncateDescription(product?.product?.description)}
                          </ReactMarkdown>
                        </p>
                      </div>

                      <div className="price_remove_cart col-lg-4 col-md-6 mb-4 mb-lg-0">
                        <p>
                          <strong>
                            Payment Status:
                            {razorpayData?.status}
                          </strong>
                        </p>
                        <p className="cart_product_price">
                          <strong>
                            Price: &#8377; {product?.product?.price}
                          </strong>
                        </p>
                        <p>
                          <strong>
                            Status of Your Order - {order?.status}
                          </strong>
                        </p>

                        <p>
                          <strong>Email: {order?.buyer?.email}</strong>
                        </p>
                        <p>
                          <strong>Address: {order?.buyer?.address}</strong>
                        </p>
                        <p>
                          <strong>
                            Ordered On -
                            {moment(order?.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </strong>
                        </p>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrderDetails;
