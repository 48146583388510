import React from "react";

const VideoForm = ({ handleSubmit, name, setName, videoLink, setVideoLink }) => {
  return (
    <>
      <form className="" onSubmit={handleSubmit}>
        <div className="row g-2 overflow-hidden">
          <div className="col-lg-5 me-2">
            <div className="form-floating">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                id="exampleInputName"
                placeholder="Enter new video name"
                required
              />
              <label for="exampleInputName" className="form-label">
                Enter new video name
              </label>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="form-floating">
              <input
                type="text"
                value={videoLink}
                onChange={(e) => setVideoLink(e.target.value)}
                className="form-control"
                id="exampleInputLink"
                placeholder="Enter new video link"
                required
              />
              <label for="exampleInputLink" className="form-label">
                Enter new video link
              </label>
            </div>
          </div>

        </div>
          <button type="submit" className="btn btn-secondary mt-2 h-100">
            Submit
          </button>
      </form>
    </>
  );
};

export default VideoForm;
