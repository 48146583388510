import React from "react";
import Layout from "../components/Layout/Layout";

function AuthorizeRating() {
  return (
    <Layout title={"Authorisation & Ratings"}>
      <div className="row contactus">
        <div className="col-md-4 ">
          <img
            src="/images/about.jpeg"
            alt="contactus"
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-md-6 contactus_para">
          <h5>
            Authorisation of being the sole PzoneRo and Classic Aquafresh Online
            distributor
          </h5>
          <p className="">
            Aquafresh Water Purifiers holds the exclusive authorization as the
            sole online distributor of two premier brands: 'Pzone RO' and
            'Classic Aquafresh'. With a rigorous selection process and stringent
            quality control measures, Aquafresh Water Purifiers ensures that
            only the highest standard products reach the market. This
            exclusivity not only underscores our commitment to excellence but
            also assures customers of the authenticity and reliability of every
            'Pzone RO' and 'Classic Aquafresh' unit they purchase. Trust
            Aquafresh Water Purifiers for unmatched quality and peace of mind in
            water purification solutions.
          </p>

          <h5>4 stars in JUST DIAL CUSTOMER CARE</h5>
          <p>
            Aquafresh Water Purifiers has proudly earned a stellar 4 out of 5
            stars in customer care and service from 'Just Dial', a testament to
            our unwavering dedication to customer satisfaction. Through prompt
            responses, personalized assistance, and expert guidance, we strive
            to exceed expectations at every touchpoint. Our commitment to
            excellence extends beyond delivering premium products to ensuring a
            seamless and fulfilling customer experience. Trust Aquafresh Water
            Purifiers for unparalleled service and care, endorsed by satisfied
            customers and recognized by esteemed platforms like 'Just Dial'.
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default AuthorizeRating;
